import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import useStyles from './checkbox-filter-style'

const CheckboxFilter = (props) => {
  const { option, selecteds, handleOnChange } = props
  const [isSelect, setIsSelect] = useState(false)
  useEffect(() => {
    setIsSelect(_.includes(selecteds, option.value))
  }, [option, selecteds])

  const style = useStyles({ isSelect })
  return (
    <li key={option.id} className={style.optionTextStyle}>
      <label htmlFor={option.id}>
        {option.hex_value
        && (
        <span
          className={style.circleColor}
          style={{
            backgroundColor: option.hex_value,
            backgroundImage: `url(${_.get(option, 'image.versions.webThumbPortrait', undefined)})`,
          }}
        />
        )}
        {option.label}
      </label>
      <input
        type="checkbox"
        id={option.id}
        value={option.value}
        name={option.value}
        checked={isSelect}
        onChange={(e) => handleOnChange(e)}
      />
      <span className={style.checkmark} />
    </li>
  )
}

export default CheckboxFilter
