import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({

  optionTextStyle: () => ({
    position: 'relative',
    minHeight: '5rem',
    borderBottom: `1px solid ${theme.colors.bgMedium}`,
    padding: '1rem 6rem 1rem 2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
    '& input[type="checkbox"]': {
      position: 'absolute',
      right: 0,
      top: 0,
      opacity: 0,
      marginRight: '0.9rem',
      width: '100%',
      height: '50px',
      zIndex: 1,
    },
    '& label': {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 1.2,
    },
  }),
  checkmark: (props) => ({
    '&:before': {
      content: '""',
      top: '50%',
      transform: 'translateY(-50%)',
      position: 'absolute',
      width: '2rem',
      height: '2rem',
      border: `1px solid ${theme.colors.bgMedium}`,
      right: '2rem',
    },
    '&:after': {
      content: '""',
      display: props.isSelect ? 'block' : 'none',
      top: '50%',
      transform: 'translateY(-50%)',
      position: 'absolute',
      width: '1.2rem',
      height: '1.2rem',
      right: '2.4rem',
      background: theme.colors.secondary,
    },
  }),
  circleColor: {
    position: 'relative',
    marginRight: '1rem',
    width: '2rem',
    height: '2rem',
    display: 'inline-block',
    borderRadius: '1rem',
    border: `1px solid ${theme.colors.bgMedium}`,
  },
}))

export default useStyles
