import React from 'react'
import CheckboxFilter from '../checkbox-filter/checkbox-filter-view'
import useStyles from './header-select-style'

const HeaderSelectView = (props) => {
  const style = useStyles()
  const { option, selecteds, handleOnChange } = props
  return (
    <li key={option.id} className={style.listStyle}>
      <div className={style.optionHeader}>
        <h6>{option.label}</h6>
      </div>
      <ul>
        {
            option && option.options.map((op) => (
              <CheckboxFilter
                key={op.id}
                option={op}
                selecteds={selecteds}
                handleOnChange={handleOnChange}
              />
            ))
          }
      </ul>
    </li>
  )
}

export default HeaderSelectView
