/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */

import _ from 'lodash'
import React, {
  useState, useEffect, useRef,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import CrossIcon from '../../assets/icons/icon_cross.svg'
import useStyles from './products-style'
import ModalFilter from './components/modal-filter/modal-filter-view'
import DesktopFilters from './components/desktop-filters/desktop-filter'
import FilterIcon from '../../assets/icons/icon_filter.svg'
import SortIcon from '../../assets/icons/icon_sort.svg'
import ArrowDownIcon from '../../assets/icons/icon_right_arrow.svg'
import {
  Breadcrumb,
  Button,
  ContentGroup,
  Layout,
  ModalCompare,
  OrderMethod,
  Pagination,
  ProductCard,
  ProductCardPlaceholder,
  SEO,
  Title,
} from '../../ui'

function ProductsView(props) {
  const { i18n, t } = useTranslation()
  const {
    availableFilters,
    brandOptions,
    breadcrumb,
    category,
    colorMasterOptions,
    colorMasterOptionsPlaceholder,
    compareData,
    distinct,
    enableComparisonEcom,
    filterOptions,
    goToCompareProducts,
    hasMoreThan,
    hasSizeMasterMore,
    inventoryStoreCode,
    isLoading,
    isOpen,
    isResetFilterActive,
    loadingNextSizeMaster,
    maxNumberComparisonEcom,
    onAddToCompare,
    onBrandChange,
    onClearCompare,
    onClickTrackEvent,
    onColorOptionChange,
    onContinueShopping,
    onFetchSizeMasterNextPage,
    onPageClick,
    onPageSizeUpdate,
    onResetFilter,
    onSizeOptionChange,
    onSortByUpdate,
    pageReady,
    pageSizeOptions,
    pageTitle,
    pagination,
    plpContentGroupCode,
    selectableOrderMethod,
    seoDescription,
    seoMeta,
    seoTitle,
    showBreadCrumb,
    showDineInOrderPlaced,
    sizeMasterOptions,
    sizeMasterOptionsPlaceholder,
    skus,
    sortByOptions,
    sortByOptionsPlaceholder,
  } = props
  const titleRef = useRef()
  const [isSelectFilters, setIsSelectFilters] = useState(false)
  const [isSelectBySort, setIsSelectBySort] = useState(false)
  const [typeFilterOpen, setTypeFilterOpen] = useState('')
  const [hasResults] = useState(false)
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)
  const [brandsFiltered, setBrandsFiltered] = useState(brandOptions)
  const [hideFilters, setHideFilters] = useState(false)

  // set values in special object as sortBy is
  // it comes from this issues in react-select https://github.com/JedWatson/react-select/issues/2674
  const selectedSortBy = _.get(filterOptions, 'sortBy')
  const selectedOptionSortBy = _.find(sortByOptions, { value: selectedSortBy })
  const RenderImage = category ? <img src={_.get(category, 'image.versions.ecomThumbMedium')} alt="" /> : ''
  const totalPages = _.get(pagination, 'totalPages', 0)
  const resultColorMasterOptions = colorMasterOptions.length > 1
    ? colorMasterOptions
    : _.get(colorMasterOptions, '[0].options', [])
  const resultSizeMasterOptions = sizeMasterOptions.length > 1
    ? sizeMasterOptions
    : _.get(sizeMasterOptions, '[0].options', [])

  const handlePageClick = (page) => {
    // pagination component page number start from 0
    const selectedPage = page.selected + 1
    if (titleRef && (_.has(filterOptions, 'page') || selectedPage > 1)) {
      window.scrollTo(0, titleRef.current.offsetTop)
    }
    onPageClick(selectedPage)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'f') {
      setIsFilterModalOpen(!isFilterModalOpen)
    }
  }

  const handelOpenMenu = (type) => {
    setTypeFilterOpen(type)
    setIsFilterModalOpen(!isFilterModalOpen)
  }

  function handleColorOptionChange(event) {
    const value = _.map(event, 'value')
    onColorOptionChange(value)
  }

  function handleSizeOptionChange(event) {
    const value = _.map(event, 'value')
    onSizeOptionChange(value)
  }

  function handleBrandOptionChange(event) {
    const value = _.map(event, 'value')
    onBrandChange(value)
  }

  function handlePageSizeOptionChange(event) {
    const value = _.get(event, 'value')
    onPageSizeUpdate(value)
  }

  function handleSortByChange(event) {
    const value = _.get(event, 'value')
    onSortByUpdate(value)
  }

  useEffect(() => {
    setIsSelectBySort(_.get(filterOptions, 'sortBy'))
    setIsSelectFilters(
      _.get(filterOptions, 'brandCodeEq', false)
      || _.get(filterOptions, 'colorMasterCodeEq', false)
      || _.get(filterOptions, 'sizeMasterCodeEq', false),
    )
  }, [filterOptions])

  useEffect(() => {
    // setHideFilters(!isSelectFilters && skus.length === 0)
    // setHideFilters(!isSelectFilters)
  }, [filterOptions, skus])

  useEffect(() => {
    setBrandsFiltered(_.sortBy(brandOptions, ['label'], ['asc']))
  }, [brandOptions])

  const styles = useStyles({ isSelectBySort, isSelectFilters })
  const availableOptionsFiltersMobile = _.get(availableFilters, 'mobileView', {})
  const availableFiltersMobile = _.get(availableOptionsFiltersMobile, 'left')

  const modalProps = {
    availableFiltersMobile,
    brandOptions: brandsFiltered,
    colorMasterOptions: resultColorMasterOptions,
    colorMasterOptionsPlaceholder,
    filterOptions,
    onFetchSizeMasterNextPage,
    hasSizeMasterMore,
    handelOpenMenu,
    i18n,
    isFilterModalOpen,
    pageSizeOptions,
    selectedOptionSortBy,
    sizeMasterOptions,
    sizeMasterOptionsPlaceholder,
    sortByOptions,
    typeFilterOpen,
    onBrandChange,
    onColorOptionChange,
    onPageSizeUpdate,
    onSizeOptionChange,
    onSortByUpdate,
  }
  const desktopFilterProps = {
    availableFilters,
    brandsFiltered,
    colorMasterOptionsPlaceholder,
    filterOptions,
    hideFilters,
    loadingNextSizeMaster,
    loadingSkus: isLoading,
    pageSizeOptions,
    resultColorMasterOptions,
    resultSizeMasterOptions,
    selectedOptionSortBy,
    sizeMasterOptionsPlaceholder,
    sortByOptionsPlaceholder,
    sortByOptions,
    styles,
    skus,
    t,
    handlePageSizeOptionChange,
    handleColorOptionChange,
    handleSizeOptionChange,
    handleBrandOptionChange,
    handleSortByChange,
    onFetchSizeMasterNextPage,
  }

  return (
    <Layout>
      {/* passing page specific data for SEO */}
      {/* TODO: add seo description and meta */}
      <SEO
        description={seoDescription}
        title={seoTitle}
        meta={seoMeta}
      />
      {
        showDineInOrderPlaced && (
          <div className={styles.dineInOrderPlacedButtonContainer}>
            <Button
              text={t('screens.products.dineInOrderPlacedButton')}
              navigate="/dine-in-order-placed/"
              iconImage={ArrowDownIcon}
              inline
              className={styles.dineInOrderPlacedButton}
            />
          </div>
        )
      }
      {
        showBreadCrumb
          ? <Breadcrumb classN={styles.breadcrumb} links={breadcrumb} />
          : <div className={styles.emptyBreadCrumb} />
      }
      <div className={styles.containerFluid}>
        <div className={styles.container}>
          {
            !_.isEmpty(selectableOrderMethod) && (
              <OrderMethod />
            )
          }
          <>
            {
            plpContentGroupCode && (
              <ContentGroup
                cat={category}
                fallback={RenderImage}
                code={plpContentGroupCode}
                template="ContentGroup"
              />
            )
            }
            <div className={styles.containerTitle} id="titleRef" ref={titleRef}>
              {/* TODO: use title from department or categories */}
              <Title uppercase text={pageTitle} />
            </div>
            {/* mobile filter */}
            <div className={styles.containerFiltersMobile}>
              <div className={styles.filterBoxMobile}>
                {
                  !_.isEmpty(availableOptionsFiltersMobile.left) && (
                    <div
                      tabIndex={0}
                      onClick={() => handelOpenMenu('filter')}
                      role="button"
                      onKeyDown={(e) => handleKeyPress(e)}
                      className={styles.boxFilter}
                    >
                      <img
                        src={FilterIcon}
                        alt=""
                      />
                      <span>{t('screens.products.titleFilters')}</span>
                    </div>
                  )
                }
                {
                  _.map(availableOptionsFiltersMobile.right, (filter) => {
                    let elem;
                    switch (true) {
                      case filter === 'sortBy':
                        elem = (
                          <div
                            className={styles.boxSortBy}
                            tabIndex={-1}
                            onClick={() => handelOpenMenu('sortBy')}
                            role="button"
                            key={filter}
                          >
                            <img src={SortIcon} alt="" />
                            <span>{t('screens.products.sort')}</span>
                          </div>
                        )
                        break;
                      case filter === 'view':
                        elem = (
                          <div
                            className={styles.filterByPageStyle}
                            tabIndex={-2}
                            onClick={() => handelOpenMenu('page')}
                            role="button"
                            key={filter}
                            onKeyDown={(e) => handleKeyPress(e)}
                          >
                            <span>
                              {t('screens.products.view')}
                              :
                            </span>
                            <p>
                              {_.get(filterOptions, 'pageSize')}
                              <img src={ArrowDownIcon} alt="" />
                            </p>
                          </div>
                        )
                        break;
                      default:
                        break;
                    }
                    return elem
                  })
                }
              </div>
              <div className={styles.boxResetBtnStyle}>
                {
                  isResetFilterActive && (
                    <Button
                      className={styles.resetBtnStyle}
                      text="Reset"
                      iconImage={CrossIcon}
                      onClick={onResetFilter}
                    />

                  )
                }
              </div>
            </div>
            {/* desktop filter */}
            <div className={styles.containerFilters}>
              <DesktopFilters {...desktopFilterProps} />
              {/*
              TODO: check FilterOptions, only show reset button
              when any filter options selected
            */}
              <div className={styles.boxResetBtnStyle}>
                {
                  isResetFilterActive
                    && (
                      <Button
                        className={styles.resetBtnStyle}
                        text="Reset"
                        iconImage={CrossIcon}
                        onClick={onResetFilter}
                      />
                    )
                }
              </div>
            </div>
            {hasResults && <div className={styles.resultStyle}>{t('screens.products.found', { items: 1, product: ['Shirt'] })}</div>}
            {isLoading ? (
              <div className={styles.containerProducts}>
                {
                  _.times(_.get(filterOptions, 'pageSize', _.get(pageSizeOptions, '0.value', 8)), (index) => (
                    <div key={`product-card-placeholder-${index}`} className={styles.productCards}>
                      <ProductCardPlaceholder />
                    </div>
                  ))
                }
              </div>
            )
              : (
                <>
                  <div>
                    {
                      totalPages > 1
                      && (
                        <Pagination
                          pagination={pagination}
                          handlePageClick={(e) => handlePageClick(e)}
                        />
                      )
                    }
                  </div>
                  <div className={styles.containerProducts}>
                    {skus.length >= 1 ? skus.map((sku, index) => (
                      <div key={`product-card-${sku.id}`} className={styles.productCards}>
                        <ProductCard
                          key={`product-card-${sku.id}`}
                          colorOptionVariantType={_.get(sku, 'product.colorOptionVariantType', {})}
                          productPage
                          imageUrl={_.get(sku, 'colorOption.images.0.versions.webLarge') || _.get(sku, 'colorOption.defaultImage.versions.webLarge')}
                          title={_.get(sku, 'product.title')}
                          colorName={_.get(sku, 'colorOption.name')}
                          sellPrice={_.get(sku, 'colorOption.sellPrice', null)}
                          originalPrice={_.get(sku, 'colorOption.originalPrice', null)}
                          activeCustomLabels={_.get(sku, 'colorOption.activeCustomLabels')}
                          colorOptionId={_.get(sku, 'colorOption.id')}
                          favourite={_.get(sku, 'colorOption.favourite')}
                          productId={_.get(sku, 'product.id')}
                          url={_.get(sku, 'product.canonicalHref')}
                          stockLevel={_.get(sku, 'colorOption.stockLevel')}
                          sku={sku}
                          distinct={distinct}
                          inventoryStoreCode={inventoryStoreCode}
                          compareData={compareData}
                          onAddToCompare={onAddToCompare}
                          hasMoreThan={hasMoreThan}
                          enableComparisonEcom={enableComparisonEcom}
                          onClickTrackEvent={onClickTrackEvent}
                          index={index + 1}
                        />
                      </div>
                    )) : (
                      <div className={styles.noResultStyle}>
                        <p>{t('screens.products.empty')}</p>
                        <Button
                          className={styles.buttonContinue}
                          dark
                          text={t('screens.products.button')}
                          onClick={onContinueShopping}
                        />
                      </div>
                    )}
                  </div>

                  <div className={styles.pagination}>
                    {
                      totalPages > 1 && (
                        <Pagination
                          pagination={pagination}
                          handlePageClick={(e) => handlePageClick(e)}
                        />
                      )
                    }
                  </div>
                </>
              )}
          </>
        </div>
      </div>
      <ModalFilter {...modalProps} />
      {
        enableComparisonEcom
        && (
        <ModalCompare
          compareData={compareData}
          onAddToCompare={onAddToCompare}
          goToCompareProducts={goToCompareProducts}
          t={t}
          isOpen={isOpen}
          onClearCompare={onClearCompare}
          maxNumberComparisonEcom={maxNumberComparisonEcom}
        />
        )
      }
    </Layout>
  )
}

ProductsView.propTypes = {
  skus: PropTypes.array,
}

ProductsView.defaultProps = {
  skus: [],
}

export default ProductsView
