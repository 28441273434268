/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import tick from '../../../../assets/icons/icon_tick.svg'
import useStyles from './list-filter-style'

const ListFilterView = ({
  onPageSizeUpdate,
  onSortByUpdate,
  selectedOptionSortBy,
  sortByOptions,
  pageSizeOptions,
  typeFilterOpen,
  filterOptions,
  handelOpenMenu,
}) => {
  const style = useStyles()
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState('')
  useEffect(() => {
    switch (typeFilterOpen) {
      case 'sortBy':
        setOptions(sortByOptions)
        setSelected(_.get(selectedOptionSortBy, 'value'))
        break;
      case 'page':
        setOptions(pageSizeOptions)
        setSelected(_.get(filterOptions, 'pageSize'))
        break;
      default:
        setOptions([])
        setSelected('')
        break;
    }
  }, [typeFilterOpen])

  const handelSelect = (value) => {
    if (typeFilterOpen === 'sortBy') {
      onSortByUpdate(value)
      handelOpenMenu()
    } else {
      onPageSizeUpdate(value)
      handelOpenMenu()
    }
  }

  return (
    <ul className={style.options}>
      {
        options.map((option) => (
          <li
            key={option.value}
            onClick={() => handelSelect(option.value)}
          >
            <p>{option.label}</p>
            {
              option.value === selected ? <img src={tick} alt="select option" /> : ''
            }
          </li>
        ))
      }
    </ul>
  )
}

export default ListFilterView
