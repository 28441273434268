import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  wrapper: () => ({
    display: 'block',
    width: '100vw',
    overflow: 'hidden',
    // height: '430px',
    background: theme.colors.background,
    // position: 'fixed',
    // bottom: props.isOptionOpen ? 0 : '-430px',
  }),
  headWrapper: {
    display: 'flex',
    height: '5rem',
    width: '100vw',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0',
    borderBottom: `1px solid ${theme.colors.bgMedium}`,
    '& h5': {
      margin: 0,
      fontSize: '1.6rem',
      fontWeight: 600,
      textTransform: 'capitalize',
    },
    '& button': {
      margin: 0,
      padding: '0.5rem',
      fontSize: '1.6rem',
      fontWeight: 400,
      width: '75px',
    },
  },
  bodyWrapper: () => ({
    position: 'relative',
    // height: '380px',
    maxHeight: 'calc(80vh - 5rem)',
    overflowY: 'auto',
    '& ul': {
      display: 'block',
      flexDirection: 'column',
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
  }),
  contentWrapper: {
    // height: 'calc(80vh - 19rem)',
    height: 'calc(80vh)',
    overflowX: 'hidden',
    overflowY: 'scroll',
    paddingBottom: 50,
  },
  listStyle: {
    width: '100%',
    display: 'block',
    minHeight: '5rem',
    margin: 0,
  },
  optionHeader: {
    minHeight: '5rem',
    padding: '0 2rem',
    backgroundColor: '#f7f7f7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& h6': {
      marginBottom: 0,
      textTransform: 'capitalize',
      fontWeight: 600,
      fontSize: '1.6rem',
    },
  },
  optionColorStyle: {
    display: 'inline-block',
    margin: '1.5rem 0.75rem',
    width: '3rem',
    height: '3rem',
    '& span': {
      height: '3rem',
      width: '3rem',
      borderRadius: '50%',
      display: 'inline-block',
      border: `1px solid ${theme.colors.bgMedium}`,
      '&.active': {
        border: `2px solid ${theme.colors.secondary}`,
      },
    },
  },
  noOption: {
    display: 'flex',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    '& p': {
      fontSize: 16,
      lineHeight: 1,
      marginBottom: 0,
      color: theme.colors.bgMedium,
      textTransform: 'uppercase',
    },
  },
}))

export default useStyles
