/* eslint-disable no-nested-ternary */
import { createUseStyles } from 'react-jss'
import Color from 'color'

const useStyles = createUseStyles((theme) => ({
  '@global': {
    'body.modal-open': {
      overflow: 'hidden',
      height: '100vh',
      width: '100%',
      position: 'fixed',
    },
  },
  modalFilterContainer: {
    display: 'block',
    backgroundColor: theme.colors.background,
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 1000,
    width: '100vw',
    maxHeight: '80vh',
    overflow: 'hidden',
    outline: 'none',
    webkitOverflowScrolling: 'touch',
  },
  modalFilterOverlay: {
    position: 'fixed',
    zIndex: 1002,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: 'hidden',
    backgroundColor: Color(theme.colors.primary).alpha(0.5).string(),
    opacity: 0,
    transition: 'opacity 0.3s',
    outline: 'none',
    '&.ReactModal__Overlay--after-open': {
      opacity: 1,
    },
  },
  modalFilter: {
    display: 'block',
    backgroundColor: theme.colors.background,
    width: '100vw',
  },
  modalFilterHead: {
    height: '5rem',
    textAlign: 'center',
    color: theme.colors.primary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.colors.bgMedium}`,
    '& h5': {
      margin: 0,
      fontSize: '1.6rem',
      fontWeight: 600,
    },
  },
  clearButton: {
    position: 'absolute',
    left: '1.5rem',
    padding: '0.5rem',
    textAlign: 'left',
    fontSize: '1.4rem',
  },
  modalFilterBody: {
    '& ul, & li, & div': {
      outline: 'none',
    },
    '& ul': {
      listStyle: 'none',
      margin: 0,
      '& li': {
        margin: 0,
      },
      '& li > div': {
        height: '5rem',
        margin: 0,
        padding: '0 2rem',
        borderBottom: `1px solid ${theme.colors.bgMedium}`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& p': {
          color: theme.colors.primary,
          fontSize: '1.4rem',
          fontWeight: 600,
          lineHeight: 3.5,
          marginBottom: 0,
        },
      },
    },
  },
}))

export default useStyles
