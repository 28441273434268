import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  options: {
    '& li': {
      position: 'relative',
      padding: '0 2rem',
      minHeight: '5rem',
      display: 'flex',
      alignItems: 'center',
      margin: 0,
      borderBottom: `1px solid ${theme.colors.bgMedium}`,
      '& p': {
        margin: 0,
        textTransform: 'uppercase',
      },
      '& img': {
        position: 'absolute',
        right: 20,

      },
    },
  },
}))

export default useStyles
