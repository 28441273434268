/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import moment from 'moment'
import flow from 'lodash/fp/flow'
import find from 'lodash/fp/find'
import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr';
import last from 'lodash/fp/last'
import filter from 'lodash/fp/filter'

import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  useBrands,
  useColorMasters,
  useSizeMasters,
  useSkus,
  useSystemSettings,
  useDepartments,
  useCategories,
  useProductGroups,
  useOrderMethod,
} from 'react-omnitech-api'
import URI from 'urijs'
import {
  useAnalytics,
  useCart,
  useLink,
  usePrevious,
  useCompare,
  useThemeConfig,
  // useAlert,
} from '../../hook'
import {
  getBannerName,
  getProductListFilterOptionsFromUrl,
  isBrowser,
  isNullOrUndefined,
  transformFilterBrands,
  transformFilterColorOptions,
  transformFilterPageSize,
  transformFilterSizeOptions,
  transformFilterSortBy,
} from '../../helpers'
import ProductsView from './products-view'

function ProductsController(props) {
  const { location, pageContext } = props

  // prepare hook
  const { getProductParams, trackEvent } = useAnalytics()
  const { navigate } = useLink()
  const { brands } = useBrands()
  const { fetchColorMasters } = useColorMasters()
  const { fetchSizeMasters } = useSizeMasters()
  const { fetchSkus } = useSkus()
  const { getSystemSetting } = useSystemSettings()
  const { departments } = useDepartments()
  const { categories } = useCategories()
  const { fetchProductGroupByCode, fetchProductGroup } = useProductGroups()
  const { i18n, t } = useTranslation()
  const {
    selectableOrderMethod,
  } = useThemeConfig()
  const {
    compareData,
    goToCompareProducts,
    addItemToCompare,
    hasMoreThan,
    isOpen,
    clearItemToCompare,
    enableComparisonEcom,
    maxNumberComparisonEcom,
  } = useCompare()
  const {
    store,
    orderMethod,
  } = useOrderMethod()
  const {
    totalItems,
    inventoryStoreCode,
  } = useCart()

  // dependence setting
  // default page size options when it is not available in system setting
  const defaultpageSizeOptions = {
    pageSizes: [8, 12, 24, 36, 48, 60],
    default: 8,
  }

  const pageSizeOptions = getSystemSetting('product.page_sizes', defaultpageSizeOptions, {})
  const sortByOptions = getSystemSetting('product.available_sorting.ecom', [])
  const productSearchQuery = getSystemSetting('api.v2.skus.index.product_search.ecom.query', {})
  const fnbEnabled = getSystemSetting('features.fnb.enable')
  const themeConfig = getSystemSetting('theme.ecom.config', {})
  const availableFilters = _.get(themeConfig, 'config.availableFilters', {
    mobileView: {
      left: ['color', 'size', 'brand'],
      right: ['sortBy', 'view'],
    },
    desktopView: {
      left: ['color', 'size', 'brand'],
      right: ['sortBy', 'view'],
    },
  })
  // prepare search parameters
  const [queryTitle, setQueryTitle] = useState(undefined)
  const urlFilterOptions = useMemo(() => {
    let options = {}
    if (isBrowser()) {
      options = getProductListFilterOptionsFromUrl(location.href)
    }

    setQueryTitle(_.get(options, 'q', undefined))
    // if page size and sort by did not exist in url params, use a default value
    const pageSize = _.toInteger(options.pageSize) || _.get(pageSizeOptions, 'default', 8)
    const sortByQuery = options.sortBy
      ? { sortBy: options.sortBy }
      : flow(
        find({ default: true }),
        get('query'),
      )(sortByOptions)
    return {
      ...options,
      ...sortByQuery,
      pageSize,
    }
  }, [location, pageSizeOptions, sortByOptions])

  // internal states
  // const [hasMore, setHasMore] = useState(false)
  // const [brandOptions, setBrandOptions] = useState([])
  const [filterOptions, setFilterOptions] = useState(urlFilterOptions)
  const [filterTypeChanged, setFilterTypeChanged] = useState('')
  const [colorMasterOptions, setColorMasterOptions] = useState([])
  const [pageReady, setPageReady] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [sizeMasterOptions, setSizeMasterOptions] = useState([])
  const [loadingNextSizeMaster, setLoadingNextSizeMaster] = useState(false)
  const [fetchNextSizeMasterOptions, setFetchNextSizeMasterOptions] = useState(null)
  const [isNextSizeMasterReady, setIsNextSizeMasterReady] = useState(false)
  const [hasSizeMasterMore, setHasSizeMasterMore] = useState(false)

  const [skus, setSkus] = useState([])
  const [pagination, setPagination] = useState({})
  const [breadcrumb, setBreadcrumb] = useState([])
  const [titleGroup, setTitleGroup] = useState('')
  const [plpContentGroupCode, setPlpContentGroupCode] = useState()
  const [category, setCategory] = useState()
  const [isResetFilterActive, setIsResetFilterActive] = useState(false)
  const [showBreadCrumb, setShowBreadCrumb] = useState(true)

  const pageTitle = useMemo(() => {
    const breadcrumbLast = flow(last, getOr('', 'text'))(breadcrumb)
    if (!_.isEmpty(queryTitle)) {
      return t('screens.products.searchBy', { query: queryTitle })
    }
    if (!_.isEmpty(titleGroup)) {
      return titleGroup
    }
    if (
      !_.isEmpty(_.get(filterOptions, 'departmentCodeEq'))
      || !_.isEmpty(_.get(filterOptions, 'categoryCodeEq'))
    ) {
      return breadcrumbLast
    }
    // == Multi-brands title
    // if (!_.isEmpty(_.get(filterOptions, 'brandCodeEq'))) {
    //   return _.join(
    //     _.map(
    //       _.filter(
    //         brands,
    //         ({ code }) => (
    //           _.includes(_.castArray(_.get(filterOptions, 'brandCodeEq', [])), code)
    //         ),
    //       ),
    //       'name',
    //     ),
    //     ', ',
    //   )
    // }

    // == Single brand title
    const brandCodes = _.castArray(_.get(filterOptions, 'brandCodeEq', []))
    if (
      !_.isEmpty(brandCodes)
      && _.size(brandCodes) === 1
    ) {
      return _.get(
        _.find(
          brands,
          ({ code }) => (
            _.first(brandCodes) === code
          ),
        ),
        'name',
      )
    }
    return breadcrumbLast
  }, [queryTitle, breadcrumb, titleGroup, filterOptions])

  // SEO Meta
  const seoTitle = useMemo(() => {
    const categoryCodeEq = _.get(filterOptions, 'categoryCodeEq')
    const departmentCodeEq = _.get(filterOptions, 'departmentCodeEq')
    if (!_.isEmpty(categoryCodeEq)) {
      return _.get(_.find(categories, { code: categoryCodeEq }), 'metaTitle', pageTitle)
    }
    if (!_.isEmpty(departmentCodeEq)) {
      return _.get(_.find(departments, { code: departmentCodeEq }), 'metaTitle', pageTitle)
    }
    return pageTitle
  }, [filterOptions, departments, categories, pageTitle])
  const seoDescription = useMemo(() => {
    const categoryCodeEq = _.get(filterOptions, 'categoryCodeEq')
    const departmentCodeEq = _.get(filterOptions, 'departmentCodeEq')
    if (!_.isEmpty(categoryCodeEq)) {
      return _.get(_.find(categories, { code: categoryCodeEq }), 'metaDescription', '')
    }
    if (!_.isEmpty(departmentCodeEq)) {
      return _.get(_.find(departments, { code: departmentCodeEq }), 'metaDescription', '')
    }
    return ''
  }, [filterOptions, departments, categories])
  const seoMeta = useMemo(() => {
    const categoryCodeEq = _.get(filterOptions, 'categoryCodeEq')
    const departmentCodeEq = _.get(filterOptions, 'departmentCodeEq')
    const meta = []
    if (!_.isEmpty(categoryCodeEq)) {
      meta.push({
        name: 'keyword',
        content: _.get(_.find(categories, { code: categoryCodeEq }), 'metaKeywords', ''),
      })
    } else if (!_.isEmpty(departmentCodeEq)) {
      meta.push({
        name: 'keyword',
        content: _.get(_.find(departments, { code: departmentCodeEq }), 'metaKeywords', ''),
      })
    }
    return meta
  }, [filterOptions, departments, categories])

  const distinct = useMemo(() => _.get(productSearchQuery, 'distinct', 'pc'), [productSearchQuery])
  // transform filter options
  const transformedBrandOptions = useMemo(() => (
    transformFilterBrands(brands)
  ), [brands])
  const transformedColorMasterOptions = useMemo(() => (
    transformFilterColorOptions(colorMasterOptions)
  ), [colorMasterOptions])
  const transformedPageSizeOptions = useMemo(() => (
    transformFilterPageSize(pageSizeOptions.pageSizes)
  ), [pageSizeOptions.pageSizes])
  const transformedSizeMasterOptions = useMemo(() => (
    transformFilterSizeOptions(sizeMasterOptions)
  ), [sizeMasterOptions])
  const transformedSortByOptions = useMemo(() => (
    transformFilterSortBy(sortByOptions, i18n.language)
  ), [sortByOptions])
  // get filter option placeholder
  const colorMasterOptionsPlaceholder = useMemo(() => {
    const numberOfOptions = transformedColorMasterOptions.length
    const firstOption = _.first(transformedColorMasterOptions) || {}
    return numberOfOptions === 1 && firstOption.id !== 'others'
      ? firstOption.label
      : t('screens.products.color')
  }, [transformedColorMasterOptions])
  const sizeMasterOptionsPlaceholder = useMemo(() => {
    const numberOfOptions = transformedSizeMasterOptions.length
    const firstOption = _.first(transformedSizeMasterOptions) || {}
    return numberOfOptions === 1 && firstOption.id !== 'others'
      ? firstOption.label
      : t('screens.products.size')
  }, [transformedSizeMasterOptions])
  const sortByOptionsPlaceholder = flow(
    find((option) => filterOptions.sortBy === option.value),
    getOr('', 'label'),
  )(transformedSortByOptions)
  const showDineInOrderPlaced = useMemo(() => (
    totalItems > 0
    && _.get(orderMethod, 'code') === 'dineIn'
  ), [totalItems, orderMethod])
  const cachedInventoryStoreCode = useMemo(() => {
    if (_.get(orderMethod, 'code', '') === 'dineInMenu') {
      return _.get(store, 'code')
    }
    return inventoryStoreCode
  }, [inventoryStoreCode, orderMethod, store])
  const skuIds = useMemo(() => _.join(_.map(skus, 'id'), ','), [skus])

  /**
   * fetchSkusApi
   * get sku search result from API
   */
  const fetchSkusApi = useCallback(async () => {
    if (!isLoading) return
    if (isNullOrUndefined(cachedInventoryStoreCode)) return
    try {
      // TODO: need to configurable for distinct by `p`, `pc`, `pcs`
      const fnbOption = {
        distinct: 'p',
        metaMenuFilterExistAll: [
          _.get(store, 'code'),
          moment().format('dddd'),
        ],
        metaMenuNotFilterNotExistAll: [
          moment().format('YYYYMMDD'),
          'PLP',
        ],
      }
      const option = {
        includes: [
          'product',
          'color_option',
          'products.color_option_variant_type',
          'products.category_ids',
          'products.brand_ids',
          ...(
            _.isEqual(distinct, 'p') ? ['products.color_option_count'] : []
          ),
        ].join(','),
        distinct,
        inventoryStoreCodeEq: cachedInventoryStoreCode,
        /* [FL] 20210507 Roku: For new format,
         * it should be product_type_eq, but backend not supported yet
         * please use product_types first
         */
        productTypes: 'product',
        ...productSearchQuery,
        ...filterOptions,
        ...(fnbEnabled && fnbOption),
      }

      // call api
      const { skus: skusData, pagination: paginationData } = await fetchSkus(option)
      setSkus(skusData)
      setPagination(paginationData)
    } catch (error) {
      // TODO: handle error
    } finally {
      setIsLoading(false)
    }
  }, [isLoading, cachedInventoryStoreCode])

  /**
   * fetchSkusByIdApi
   * get sku by ids for non cached data
   */
  const fetchSkusByIdApi = useCallback(async () => {
    if (isNullOrUndefined(cachedInventoryStoreCode)) return
    if (_.isEmpty(skuIds)) return
    try {
      const option = {
        id: skuIds,
        includes: [
          'color_option',
          'color_options.active_custom_labels',
          'color_options.favourite',
          'color_options.price_details',
          'color_options.stock_level',
        ].join(','),
        inventoryStoreCodeEq: cachedInventoryStoreCode,
        productTypes: 'product',
        pageSize: 9999,
        pageCountless: true,
      }

      // call api
      const { skus: skusData } = await fetchSkus(option)
      // Combine data
      setSkus((prevSkus) => (
        _.map(prevSkus, (prevSku) => {
          const detailedColorOption = _.get(_.find(skusData, { id: _.get(prevSku, 'id') }), 'colorOption', {})
          return {
            ...prevSku,
            colorOption: detailedColorOption,
          }
        })
      ))
    } catch (error) {
      // TODO: handle error
    } finally {
      setPageReady(true)
    }
  }, [cachedInventoryStoreCode, skuIds])

  /**
   * fetchColorMastersApi
   * get sku search filter color options from API
   */
  const fetchColorMastersApi = useCallback(async () => {
    try {
      // api call option
      // TODO: include other filters
      const option = {
        includes: [
        ].join(','),
        ..._.omit(filterOptions, [
          'colorMasterCodeEq',
          'page',
          'pageSize',
        ]),
      }
      // call api
      const { colorMasters: data } = await fetchColorMasters(option)
      setColorMasterOptions(data)
      // TODO: load next page
    } catch (error) {
      // TODO: handle error
    }
  }, [fetchColorMasters, filterOptions])

  /**
   * fetchSizeMastersApi
   * get sku search filter size options from API
   */
  const fetchSizeMastersApi = useCallback(async (fetchNextPage) => {
    setLoadingNextSizeMaster(true)
    try {
      // api call option
      // TODO: include other filters
      const option = {
        includes: [
        ].join(','),
        ..._.omit(filterOptions, [
          'sizeMasterCodeEq',
          'page',
          'pageSize',
        ]),
      }
      // call api
      const { sizeMasters: data, next } = _.isFunction(fetchNextPage)
        ? await fetchNextPage()
        : await fetchSizeMasters(option)

      setSizeMasterOptions((prevState) => _.uniqBy(prevState.concat(data), 'id'))
      setFetchNextSizeMasterOptions(() => next)
      setHasSizeMasterMore(_.isFunction(next))
      if (_.isEmpty(fetchNextPage)) {
        setIsNextSizeMasterReady(true)
      }
    } catch (error) {
      // TODO: handle error
      console.error('fetchOrders error: ', error)
    } finally {
      setLoadingNextSizeMaster(false)
    }
  }, [fetchSizeMasters, filterOptions])

  function handleFetchSizeMasterNextPage() {
    if (!_.isFunction(fetchNextSizeMasterOptions)) return
    fetchSizeMastersApi(fetchNextSizeMasterOptions)
  }

  /**
   * productGroups, get Title
   * get Categories to build the breadcrumb
   */
  const fetchProductGroupByCodeApi = useCallback(async () => {
    // HOTFIX: put inside onFetchTitleProducts to prevent build error
    try {
      const productGroupCodeEq = _.get(filterOptions, 'productGroupCodeEq')
      // get productGroupId - also look for productGroupIds for backwards compatibility
      const productGroupId = _.get(filterOptions, 'productGroupId') || _.head(_.get(filterOptions, 'productGroupIds[]'))

      let data

      if (!_.isEmpty(productGroupCodeEq)) {
        const { productGroup } = await fetchProductGroupByCode({
          code: productGroupCodeEq,
        })
        data = productGroup
        setTitleGroup(_.get(data, 'title', ''))
      }
      if (!_.isEmpty(productGroupId)) {
        const { productGroup } = await fetchProductGroup({
          id: productGroupId,
        })
        data = productGroup
        setTitleGroup(_.get(data, 'title', ''))
      }
    } catch (error) {
      // TODO: handle error
    }
  }, [fetchProductGroupByCode])

  const updateUrlParams = useCallback((options) => {
    const url = new URI(location.pathname)
    url.search({
      // ...url.search(true),
      ...options,
    })
    const newUrl = url.href()

    // JL20200730: if the new parameter is not exist in old url, will trigger full page load
    // changed to use history api directly.
    // fallback to use navigation function when got error
    try {
      window.history.replaceState('', 'Title', newUrl)
    } catch (error) {
      navigate(newUrl, { replace: true })
    }
  }, [location])

  const cleanUrlParams = useCallback((options) => {
    const url = new URI(location.pathname)
    url.search(options)
    const newUrl = url.href()

    // JL20200730: if the new parameter is not exist in old url, will trigger full page load
    // changed to use history api directly.
    // fallback to use navigation function when got error
    try {
      window.history.replaceState('', 'Title', newUrl)
    } catch (error) {
      navigate(newUrl, { replace: true })
    }
  }, [location])

  function handleFilterOptionUpdate(type, value) {
    setPageReady(false)
    let newFilterOption = {}
    if (type === 'pageSize' || type === 'sortBy') {
      newFilterOption = {
        ..._.omit(filterOptions, [
          'page',
        ]),
        [`${type}`]: value,
      }
    } else {
      newFilterOption = {
        ...filterOptions,
        page: 1,
        [`${type}`]: value,
      }
    }
    setFilterOptions(newFilterOption)
    setFilterTypeChanged(type)
    // update browser url with latest filter option
    updateUrlParams(newFilterOption)
  }

  /**
   * handleBreadcrumb
   * create array for breadcrumb, first use Promise all to get Department and category
   */
  const handleBreadcrumb = useCallback(() => {
    const url = new URI(location.href)
    const initialBreadcrumb = [
      {
        text: 'All',
        url: '/products',
      },
    ]
    const queryDepartment = url.query(true).departmentCodeEq
    const queryCategory = url.query(true).categoryCodeEq

    // Use Categories & Departments from localStorage
    const departmentData = _.get(_.find(departments, { code: queryDepartment }), 'name')
    const categoryData = _.get(_.find(categories, { code: queryCategory }), 'name')
    setCategory(_.find(categories, { code: queryCategory }))
    if (queryDepartment) {
      if (queryCategory) {
        initialBreadcrumb.push({
          text: departmentData,
          url: `/products/?departmentCodeEq=${queryDepartment}`,
        }, {
          text: categoryData,
          url: '',
        })
      } else {
        initialBreadcrumb.push({
          text: departmentData,
          url: '',
        })
      }
      setBreadcrumb(initialBreadcrumb)
    } else if (queryCategory) {
      initialBreadcrumb.push({
        text: categoryData,
        url: '',
      })
      setBreadcrumb(initialBreadcrumb)
    } else {
      setBreadcrumb(initialBreadcrumb)
    }
    return {
      department: departmentData,
      category: categoryData,
    }
  }, [location.href, departments, categories])

  function handleBrandChange(value) {
    handleFilterOptionUpdate('brandCodeEq', _.castArray(value))
  }

  function handleColorOptionChange(value) {
    handleFilterOptionUpdate('colorMasterCodeEq', _.castArray(value))
  }

  function handleSizeOptionChange(value) {
    handleFilterOptionUpdate('sizeMasterCodeEq', _.castArray(value))
  }

  function handlePageSizeUpdate(value) {
    handleFilterOptionUpdate('pageSize', value)
  }

  function handleSortByUpdate(value) {
    handleFilterOptionUpdate('sortBy', value)
  }

  function handleResetFilter() {
    // TODO: clear filters
    // use default sort by
    // use default page view
    const categoryCodeEq = _.get(filterOptions, 'categoryCodeEq')
    const departmentCodeEq = _.get(filterOptions, 'departmentCodeEq')
    const productGroupCodeEq = _.get(filterOptions, 'productGroupCodeEq')
    const pageSize = _.get(pageSizeOptions, 'default', 8)
    const sortByQuery = flow(
      filter({ default: true }),
      get('[0]query'),
    )(sortByOptions)

    setFilterTypeChanged('')
    setFilterOptions({
      ...sortByQuery,
      categoryCodeEq,
      departmentCodeEq,
      pageSize,
      productGroupCodeEq,
    })
    setQueryTitle(undefined)
    cleanUrlParams({
      categoryCodeEq,
      departmentCodeEq,
      productGroupCodeEq,
      pageSize,
    })
  }

  const handleFetchFilterOptions = useCallback((excludedFilterType) => {
    const filters = {
      colorMasterCodeEq: fetchColorMastersApi,
      sizeMasterCodeEq: fetchSizeMastersApi,
    }
    _.each(filters, (fn, key) => {
      if (excludedFilterType === key) return
      fn()
    })
  }, [fetchColorMastersApi, fetchSizeMastersApi])

  function handlePageClick(selectedPage) {
    const { currentPage } = pagination
    if (currentPage === selectedPage) return

    handleFilterOptionUpdate('page', selectedPage)
  }

  function handleContinueShopping() {
    navigate('/')
  }

  function handleAddToCompare(item, isInStore = false) {
    const objProduct = {
      id: _.get(item, 'id', ''),
      productId: isInStore ? _.get(item, 'productId') : _.get(item, 'product.id'),
      image: isInStore ? _.get(item, 'image')
        : _.get(item, 'colorOption.defaultImage.versions.webThumb', ''),
    }
    addItemToCompare(objProduct)
  }

  function trackProductsImpression() {
    const products = _.map(skus, (sku, skuIdx) => ({
      ...getProductParams(sku),
      list: 'Search Results',
      position: (skuIdx + 1),
    }))
    trackEvent('viewProductImpression', {}, { products, title: seoTitle })
  }

  function handleClickTrackEvent(eventName, product) {
    trackEvent(eventName, {}, { product })
  }

  useEffect(() => {
    fetchSkusApi()
  }, [fetchSkusApi])

  useEffect(() => {
    fetchSkusByIdApi()
  }, [fetchSkusByIdApi])

  useEffect(() => {
    handleBreadcrumb()
  }, [handleBreadcrumb])

  useEffect(() => {
    // FL: use isLoading flag to make sure trigger fetchSkuApi once only
    setIsLoading(true)
  }, [
    _.get(store, 'id'),
    filterOptions,
    _.get(orderMethod, 'commerceChannel'),
    _.get(orderMethod, 'deliveryType'),
    _.get(orderMethod, 'commerceType'),
    _.get(orderMethod, 'code'),
  ])

  useEffect(() => {
    if (
      _.isEmpty(skus)
      || !pageReady
    ) return
    trackProductsImpression()
  }, [skus, pageReady])

  // when filter options is updated, refresh the option
  useEffect(() => {
    handleFetchFilterOptions(filterTypeChanged)
  }, [filterTypeChanged, handleFetchFilterOptions])

  // help search input in PLP to reload if the query is different
  const prevQuery = usePrevious(location.state)

  useEffect(() => {
    switch (true) {
      case location.state && prevQuery && prevQuery.query !== location.state.query:
      case prevQuery === null:
        setFilterOptions((prevFilterOptions) => ({
          ...prevFilterOptions,
          q: _.get(location, 'state.query'),
        }))
        break;
      default:
        break;
    }
  }, [location])

  useEffect(() => {
    fetchProductGroupByCodeApi()
    if (_.has(filterOptions, 'productGroupCodeEq')) {
      setShowBreadCrumb(false)
    } else {
      setShowBreadCrumb(true)
    }
    const bannerName = getBannerName(filterOptions)
    setPlpContentGroupCode(bannerName)
  }, [filterOptions])

  useEffect(() => {
    if (
      _.has(filterOptions, 'brandCodeEq')
      || _.has(filterOptions, 'colorMasterCodeEq')
      || _.has(filterOptions, 'sizeMasterCodeEq')
    ) {
      setIsResetFilterActive(true)
    } else {
      setIsResetFilterActive(false)
    }
  }, [filterOptions])

  const viewProps = {
    availableFilters,
    brandOptions: transformedBrandOptions,
    colorMasterOptions: transformedColorMasterOptions,
    colorMasterOptionsPlaceholder,
    distinct,
    filterOptions,
    pageReady,
    isLoading,
    pagination,
    pageSizeOptions: transformedPageSizeOptions,
    sizeMasterOptions: transformedSizeMasterOptions,
    sizeMasterOptionsPlaceholder,
    skus,
    sortByOptions: transformedSortByOptions,
    sortByOptionsPlaceholder,
    breadcrumb,
    plpContentGroupCode,
    category,
    isResetFilterActive,
    pageContext,
    showBreadCrumb,
    showDineInOrderPlaced,
    pageTitle,
    seoTitle,
    hasMoreThan,
    enableComparisonEcom,
    maxNumberComparisonEcom,
    compareData,
    goToCompareProducts,
    isOpen,
    selectableOrderMethod,
    loadingNextSizeMaster,
    isNextSizeMasterReady,
    hasSizeMasterMore,
    inventoryStoreCode: cachedInventoryStoreCode,
    seoDescription,
    seoMeta,
    onBrandChange: handleBrandChange,
    onColorOptionChange: handleColorOptionChange,
    onContinueShopping: handleContinueShopping,
    onPageClick: handlePageClick,
    onPageSizeUpdate: handlePageSizeUpdate,
    onResetFilter: handleResetFilter,
    onSizeOptionChange: handleSizeOptionChange,
    onSortByUpdate: handleSortByUpdate,
    onAddToCompare: handleAddToCompare,
    onClearCompare: clearItemToCompare,
    onClickTrackEvent: handleClickTrackEvent,
    onFetchSizeMasterNextPage: handleFetchSizeMasterNextPage,
  }

  return (
    <ProductsView {...viewProps} />
  )
}

export default ProductsController
