import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  headWrapper: {
    display: 'flex',
    height: '5rem',
    width: '100vw',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0',
    borderBottom: `1px solid ${theme.colors.bgMedium}`,
    '& h5': {
      margin: 0,
      fontSize: '1.6rem',
      fontWeight: 600,
      textTransform: 'capitalize',
    },
    '& button': {
      margin: 0,
      padding: '0.5rem',
      fontSize: '1.6rem',
      fontWeight: 400,
      width: '75px',
    },
  },
  optionHeader: {
    minHeight: '5rem',
    padding: '0 2rem',
    backgroundColor: '#f7f7f7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& h6': {
      marginBottom: 0,
      textTransform: 'capitalize',
      fontWeight: 600,
      fontSize: '1.6rem',
    },
  },
  listStyle: {
    width: '100%',
    display: 'block',
    minHeight: '5rem',
    margin: 0,
  },
}))

export default useStyles
