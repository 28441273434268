/* eslint-disable radix */
/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import _ from 'lodash'
import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import ReactModal from 'react-modal'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import useStyles from './modal-filter-style'
import { Button } from '../../../../ui'
import WrapperSelectView from '../wrapper-select/wrapper-select-view'
import ListTypeFilter from '../list-type-filters/list-type-filters-view'
import ListFilter from '../list-filter/list-filter-view'

const ModalFilterView = ({
  availableFiltersMobile,
  brandOptions,
  colorMasterOptions,
  colorMasterOptionsPlaceholder,
  filterOptions,
  handelOpenMenu,
  hasSizeMasterMore,
  i18n,
  isFilterModalOpen,
  pageSizeOptions,
  selectedOptionSortBy,
  sizeMasterOptions,
  sizeMasterOptionsPlaceholder,
  sortByOptions,
  typeFilterOpen,
  onBrandChange,
  onColorOptionChange,
  onPageSizeUpdate,
  onSizeOptionChange,
  onSortByUpdate,
  onFetchSizeMasterNextPage,
}) => {
  const { t } = useTranslation()
  const [currentScrollTop, setCurrentScrollTop] = useState(0)
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const [selectedByType, setSelectedByType] = useState([])
  const [options, setOptions] = useState([])
  const [type, setType] = useState('')
  // const contentRef = useRef()
  // const selectContentRef = useRef()
  const style = useStyles({
    isFilterModalOpen,
    isOptionOpen,
  })

  useEffect(() => {
    const top = document.documentElement.scrollTop || document.body.scrollTop
    document.body.classList[isFilterModalOpen ? 'add' : 'remove']('modal-open')
    if (isFilterModalOpen) {
      setCurrentScrollTop(top)
      document.body.style.top = `-${top}px`
      document.body.style.height = `calc(100vh + ${top}px)`
    } else {
      document.body.style.removeProperty('top')
      document.body.style.removeProperty('height')
      window.scrollTo(0, parseInt(currentScrollTop))
    }
  }, [isFilterModalOpen])

  const contentRef = useCallback((node) => {
    if (node !== null) {
      const action = isFilterModalOpen ? disableBodyScroll : enableBodyScroll
      action(node)
    } else {
      clearAllBodyScrollLocks()
    }
  }, [isFilterModalOpen]);

  const selectContentRef = useCallback((node) => {
    if (node !== null) {
      const action = isFilterModalOpen ? disableBodyScroll : enableBodyScroll
      action(node)
    } else {
      clearAllBodyScrollLocks()
    }
  }, [isFilterModalOpen]);

  useEffect(() => {
    // remove modal-open class from body when componentDidMount
    document.body.classList.remove('modal-open')
    document.getElementsByTagName('body')[0].setAttribute('style', '')
    clearAllBodyScrollLocks()
    window.scrollTo(0, parseInt(currentScrollTop))
  }, [])

  const title = useMemo(() => {
    let text = typeFilterOpen === 'filter'
      ? t('screens.products.titleFilters')
      : typeFilterOpen === 'sortBy'
        ? t('screens.products.sort')
        : t('screens.products.view')
    switch (type) {
      case 'color':
        text = colorMasterOptionsPlaceholder
        break
      case 'size':
        text = sizeMasterOptionsPlaceholder
        break
      default:
        break
    }
    return text
  }, [colorMasterOptionsPlaceholder, sizeMasterOptionsPlaceholder, typeFilterOpen, type])

  const handelOpenOptions = (option, typeBy) => {
    if (!isOptionOpen) {
      switch (typeBy) {
        case 'color':
          setSelectedByType([..._.get(filterOptions, 'colorMasterCodeEq', [])])
          setType(typeBy)
          break;
        case 'size':
          setSelectedByType([..._.get(filterOptions, 'sizeMasterCodeEq', [])])
          setType(typeBy)
          break;
        case 'brand':
          setSelectedByType([..._.get(filterOptions, 'brandCodeEq', [])])
          setType(typeBy)
          break;
        default:
          setType('')
          setOptions([])
          setSelectedByType([])
          break;
      }
      setOptions(option)
    } else {
      setType('')
      setOptions([])
      setSelectedByType([])
    }
    setIsOptionOpen(!isOptionOpen)
  }

  useEffect(() => {
    // RJulia 2020/05/13 eventualy solution until we create
    // pagination for all filters
    if (isOptionOpen && type === 'size') {
      setOptions(sizeMasterOptions)
    }
  }, [sizeMasterOptions])

  const listTypeFiltersProps = {
    i18n,
    availableFiltersMobile,
    brandOptions,
    colorMasterOptions,
    colorMasterOptionsPlaceholder,
    sizeMasterOptions,
    sizeMasterOptionsPlaceholder,
    handelOpenOptions,
  }

  const onHandelOpenMenu = () => {
    handelOpenMenu()
    setIsOptionOpen(false)
    setType('')
  }

  const listFiltersProps = {
    i18n,
    onPageSizeUpdate,
    onSortByUpdate,
    selectedOptionSortBy,
    sortByOptions,
    pageSizeOptions,
    typeFilterOpen,
    filterOptions,
    handelOpenMenu,
  }
  return (
    <ReactModal
      closeTimeoutMS={0}
      className={style.modalFilterContainer}
      overlayClassName={style.modalFilterOverlay}
      isOpen={isFilterModalOpen}
      onRequestClose={onHandelOpenMenu}
      // {...others}
    >
      {
        !isOptionOpen ? (
          <div className={style.modalFilter}>
            <div className={style.modalFilterHead}>
              <Button
                inline
                text={t('screens.products.cancel')}
                type="button"
                onClick={handelOpenMenu}
                className={style.clearButton}
              />
              <h5>{title}</h5>
            </div>
            <div className={style.modalFilterBody} ref={contentRef}>
              {
                typeFilterOpen === 'filter' ? (
                  <ListTypeFilter
                    {...listTypeFiltersProps}
                  />

                )
                  : (
                    <ListFilter
                      {...listFiltersProps}
                    />
                  )
              }
            </div>
          </div>
        ) : (
          <WrapperSelectView
            ref={{
              bodyRef: selectContentRef,
            }}
            title={title}
            type={type}
            isOptionOpen={isOptionOpen}
            handelOpenOptions={handelOpenOptions}
            values={options}
            setIsOptionOpen={setIsOptionOpen}
            selectedByType={selectedByType}
            onColorOptionChange={onColorOptionChange}
            onSizeOptionChange={onSizeOptionChange}
            onFetchSizeMasterNextPage={onFetchSizeMasterNextPage}
            hasSizeMasterMore={hasSizeMasterMore}
            onBrandChange={onBrandChange}
            handelOpenMenu={onHandelOpenMenu}
          />
        )
      }
    </ReactModal>
  )
}

export default ModalFilterView
