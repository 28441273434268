/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import _ from 'lodash'
import React from 'react'
import SolidArrow from '../../../../assets/icons/icon_solid_arrow.svg'

const ItemTypeFIlter = ({
  id, handelOpenOptions, options, type, placehoder,
}) => (
  <li>
    <div
      onClick={() => handelOpenOptions(options, type)}
      role="button"
      tabIndex={id}
    >
      <p>{placehoder}</p>
      <div>
        <img src={SolidArrow} alt="" />
      </div>
    </div>
  </li>
)

const ListTypeFilterView = ({
  i18n,
  availableFiltersMobile,
  brandOptions,
  colorMasterOptions,
  colorMasterOptionsPlaceholder,
  sizeMasterOptions,
  sizeMasterOptionsPlaceholder,
  handelOpenOptions,
}) => {
  const typesFilter = [{
    type: 'color',
    options: colorMasterOptions,
    placehoder: colorMasterOptionsPlaceholder,
  }, {
    type: 'size',
    options: sizeMasterOptions,
    placehoder: sizeMasterOptionsPlaceholder,
  },
  {
    type: 'brand',
    options: brandOptions,
    placehoder: i18n.t('screens.products.brands'),
  }]

  return (
    <ul>
      {
      _.map(typesFilter, (type, id) => {
        if (_.includes(availableFiltersMobile, type.type)) {
          return (
            <ItemTypeFIlter
              key={id}
              id={id}
              {...type}
              handelOpenOptions={handelOpenOptions}
            />
          )
        }
      })
    }
    </ul>
  )
}

export default ListTypeFilterView
